<template>
  <section class="main-wrap">
    <header class="inner-page-header">
      <h1 class="big-title">Manage users</h1>
      <router-link to="/invite-users">
        <div class="bt">
          <p>Invite a user</p>
          <img alt="Plus" class="bt-img" src="@/assets/img/plus-icon.svg" />
        </div>
      </router-link>
    </header>

    <div class="users-wrap">
      <div class="label-wrap">
        <p class="users">Users</p>
        <p class="clients">Clients</p>
        <p class="type">Type</p>
      </div>

      <div v-if="usersList.length && clients && account && account[0] && usersList && accountUsersList">
        <router-link
          v-for="(userId, index) in accountUsersList"
          :key="index"
          :to="{ name: 'UpdateUsers', params: { userId: userId } }"
          :class="[
            'user-wrap',
            {
              'is-owner':
                userId === account[0].owner || userId === user.id || (user.id !== account[0].owner && account[0].usersRoles[userId].role === 'admin'),
            },
          ]"
        >
          <div class="user-infos">
            <img v-if="account[0].profileImg" alt="user-img" :src="account[0].profileImg" />
            <span v-else :class="['account-empty-image-letter']">{{ account[0].businessName[0].toUpperCase() }}</span>
            <div class="data">
              <p class="name">{{ usersList.find((i) => i.id === userId) ? usersList.find((i) => i.id === userId).displayName : 'User Deleted' }}</p>
              <p class="mail">
                {{ usersList.find((i) => i.id === userId) ? usersList.find((i) => i.id === userId).email : 'Will be removed automatically' }}
              </p>
            </div>
          </div>
          <div class="user-clients">
            <p v-if="!account[0].usersRoles[userId].clients || account[0].usersRoles[userId].role === 'admin'">All</p>
            <div v-else-if="account[0].usersRoles[userId].clients">
              <p>
                <span v-for="(clientId, indexB) in account[0].usersRoles[userId].clients" :key="indexB" class="word">
                  {{ clients.find((i) => i.id === clientId) ? clients.find((i) => i.id === clientId).name : '' }}<span class="word-separator">,</span>
                </span>
              </p>
            </div>
          </div>
          <div class="user-type">
            <!-- ExL admin => Admin -->
            <p v-if="userId !== account[0].owner">
              {{ account[0].usersRoles[userId].role.charAt(0).toUpperCase() + account[0].usersRoles[userId].role.slice(1) }}
            </p>
            <p v-if="userId === account[0].owner">Owner</p>
          </div>
          <div v-if="userId === account[0].owner || userId === user.id || account[0].usersRoles[userId].role === 'admin'" class="dots"></div>
          <div v-else class="dots">
            <svg width="30" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="10" r="2" fill="#637082" />
              <circle cx="15" cy="10" r="2" fill="#637082" />
              <circle cx="22" cy="10" r="2" fill="#637082" />
            </svg>
          </div>
        </router-link>
      </div>

      <div v-if="pendingInvites.length && clients && account && account[0]">
        <div v-for="(invitedUser, index) in pendingInvites" :key="index" :class="['user-wrap', 'is-pending']">
          <div class="user-infos">
            <img alt="user-img" :src="account[0].profileImg" />
            <div class="data">
              <p class="name">{{ invitedUser.displayName ? invitedUser.displayName : 'N/A' }}</p>
              <p class="mail">{{ invitedUser.email }}</p>
            </div>
          </div>
          <div class="user-clients">
            <p v-if="!invitedUser.clients || invitedUser.role === 'admin'">All</p>
            <div v-else-if="invitedUser.clients">
              <p>
                <span v-for="(clientId, indexB) in invitedUser.clients" :key="indexB" class="word">
                  {{ clients.find((i) => i.id === clientId) ? clients.find((i) => i.id === clientId).name : '' }}<span class="word-separator">,</span>
                </span>
              </p>
            </div>
          </div>
          <div class="user-type">
            <p>Pending</p>
          </div>
          <div class="dots">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-mail-forward"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#4628ff"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              @click.prevent="resendPendingInvite(invitedUser)"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 18h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5" />
              <path d="M3 6l9 6l9 -6" />
              <path d="M15 18h6" />
              <path d="M18 15l3 3l-3 3" />
            </svg>
          </div>
          <div class="spacer">&nbsp;</div>
          <div class="dots">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-trash"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#f12f80"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              @click.prevent="deletePendingInvite(invitedUser)"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <line x1="4" y1="7" x2="20" y2="7" />
              <line x1="10" y1="11" x2="10" y2="17" />
              <line x1="14" y1="11" x2="14" y2="17" />
              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isNil } from 'lodash'
// import ContextualToggleEdit from '@/components/actions/ContextualToggleEdit'
import { doAlert } from '@/misc/helpers'

import UsersDB from '@/firebase/users-db'
import InvitesDB from '@/firebase/invites-db'

export default {
  head: function () {
    return {
      title: {
        inner: 'Manage users',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} Manage page`,
          id: 'desc',
        },
      ],
    }
  },
  data() {
    return {
      usersList: [],
      pendingInvites: [],
    }
  },
  computed: {
    accountUsersList() {
      if (!this.user || !this.account || !this.account[0]) {
        return []
      }

      // Used to hide your own user from the list
      // return this.account[0].usersList.filter( i => i !== this.user.id )

      return this.account[0].usersList
    },
    ...mapState('authentication', ['user']),
    ...mapState('users', ['account', 'clients']),
  },
  watch: {
    account: {
      async handler(account) {
        if (!isNil(account)) {
          if (!account || !account[0]) {
            return
          }

          this.getUserAccount()

          this.refreshClientList()

          this.fetchUsersList()

          const invitesDBRef = new InvitesDB()
          const pendingInvites = await invitesDBRef.readAll([['account.id', '==', account[0].id]])

          this.pendingInvites = pendingInvites
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchUsersList()
  },
  methods: {
    async fetchUsersList() {
      const usersDBRef = new UsersDB()
      this.usersList = await usersDBRef.readAll()
    },
    async deletePendingInvite(invite) {
      this.$swal({
        title: 'Cancel invite',
        text: `Are you sure you want to cancel this pending invite for ${invite.email}?`,
        showCloseButton: true,
        showCancelButton: true,
        imageUrl: '/img/icons/delete.svg',
        confirmButtonColor: '#4628FF',
        cancelButtonColor: '#637082',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.value) {
          const invitesDB = new InvitesDB()
          invitesDB.delete(invite.id)
          const pendingInvites = await invitesDB.readAll([['account.id', '==', this.account[0].id]])
          this.pendingInvites = pendingInvites
        }
      })
    },
    async resendPendingInvite(invite) {
      // console.log({invite})

      this.$swal({
        title: 'Resend',
        text: `Are you sure you want to resend this pending invite for ${invite.email}?`,
        showCloseButton: true,
        showCancelButton: true,
        imageUrl: '/img/icons/duplicate.svg',
        confirmButtonColor: '#4628FF',
        cancelButtonColor: '#637082',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.value) {
          const invitesDB = new InvitesDB()
          invitesDB.update({ ...invite, updateTimestamp: new Date() })
          doAlert({ title: `Invite sent`, type: 'success' })
        }
      })
    },
    refreshClientList() {
      this.getUserClients()
    },
    ...mapActions('users', ['getUserAccount', 'getUserClients']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/button.scss';

.inner-page-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 35px;

  .bt {
    min-width: 0;
  }
}

.label-wrap {
  display: flex;
  margin-bottom: 15px;

  .users {
    width: calc(35% - 30px);
  }

  .clients {
    width: calc(50% - 40px);
  }

  .type {
    width: 15%;
  }

  p {
    font-weight: 600;
    color: $dark-grey;
    font-size: 14px;
    opacity: 0.5;
  }
}

.user-wrap {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #e0e2e6;
  padding: 15px;
  margin-bottom: 10px;
  transition: all 0.5s ease;

  &.is-owner,
  &.is-pending {
    opacity: 0.75;
    background-color: #f9f9fb;
    cursor: default;
  }

  p {
    font-weight: 600;
    color: $dark-grey;
    font-size: 14px;
  }

  .user-infos {
    display: flex;
    align-items: center;
    width: calc(35% - 30px);

    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-right: 10px;
    }

    .mail {
      font-size: 12px;
      opacity: 0.5;
    }
  }

  .user-clients {
    width: calc(50% - 30px);
  }

  .user-type {
    width: 15%;
  }

  .dots {
    display: flex;
    cursor: pointer;
  }

  .spacer {
    min-width: 10px;
  }
}

.word:last-child {
  .word-separator {
    display: none;
  }
}
</style>
